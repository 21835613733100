import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  Col, KarlaInput,
  Row,
  StyledButton,
  StyledButtonText, StyledDisclaimerText,
  StyledSectionHeader,
} from '../../components/Layout';
import MerchantLayout from '../../components/Layout/MerchantLayout';
import merchants from '../../merchants.json';
import { apiUpdate } from '../../services/sendToApi';

const FeedbackReactionColumn = styled(Col)`
  transition: all 0.3s ease-in;
`;

const StyledWrongButton = styled.button`
  background-color: ${(props) => (props.selected ? '#8d9299' : '#f0f3f4')};
  cursor: pointer;
  border-radius: 0px;
  border: none;
  width: 100%;
   max-width: 350px;
  height: 58px;
  padding: 0px;
  color: ${(props) => (props.selected ? '#ffffff' : '#000000')};
`;

function WrongButton({
  answer, selected, onClick,
}) {
  return (
    <Row>
      <StyledWrongButton selected={selected} onClick={onClick}>
        <StyledButtonText>
          {answer}
        </StyledButtonText>
      </StyledWrongButton>
    </Row>
  );
}

function IndexPage({ location }) {
  const { t, i18n } = useTranslation();
  const { state = {} } = location;
  const { id, merchant, trackingNumber } = state;

  if (!merchant) {
    return <div />;
  }

  const i18nAnswers = merchants[merchant].answers[i18n.language];
  const [selectedAnswers, setSelectedAnswers] = useState(Array(i18nAnswers.length));

  const submitFeedback = () => {
    const answers = merchants[merchant].answers.en
      .filter((answer, idx) => selectedAnswers[idx]);

    apiUpdate(id, { answers });

    navigate(selectedAnswers[5] ? '/merchant/fulltext' : '/merchant/sent', { state: { answers, ...state } });
  };

  const noneSelected = selectedAnswers.filter((s) => s).length === 0;

  return (
    <MerchantLayout merchant={merchant}>
      <Row>
        <StyledSectionHeader>
          {t('successPage.trackingRecognized')}
        </StyledSectionHeader>
      </Row>
      <Row>
        <KarlaInput type="text" value={trackingNumber} disabled hasSideIcon />
      </Row>

      {/* Rate Response (2nd screen) */}
      <FeedbackReactionColumn>
        <Row>
          <StyledSectionHeader>
            {t('merchant.whatswrong.title')}
          </StyledSectionHeader>
        </Row>
        <Row>
          <StyledDisclaimerText>
            {t('merchant.whatswrong.text')}
          </StyledDisclaimerText>
        </Row>
        {i18nAnswers.map((answer, index) => (
          <WrongButton
            key={answer}
            state={state}
            answer={answer}
            selected={selectedAnswers[index]}
            onClick={() => {
              const newAnswers = [...selectedAnswers];
              newAnswers[index] = !newAnswers[index];
              setSelectedAnswers(newAnswers);
            }}
          />
        ))}
        {noneSelected || (
        <Row>
          <StyledButton disabled={noneSelected} onClick={submitFeedback}>
            <StyledButtonText>
              {t('misc.shareFeedback')}
            </StyledButtonText>
          </StyledButton>
        </Row>
        )}
      </FeedbackReactionColumn>
    </MerchantLayout>
  );
}

export default IndexPage;
